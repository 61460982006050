export default [
  {
    path: "/works",
    text: "works",
  },
  // {
  //   path: "/blog",
  //   text: "blog",
  // },
  {
    path: "/blogs",
    text: "blogs",
  },
]
